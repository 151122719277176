<template>
    <div id="company-list">

        <b-modal hide-footer size="sm" ref="adminUser-modal" title="compte administrateur">
            <b-row>

                <b-col cols="12">
                    <b-form-group label="Nom d'utilisateur">
                        <b-form-input v-model="adminUser.username" placeholder="Nom d'utilisateur"
                            :disabled="!adminUser.isNew" ref="adminUserInput" id="username" name="username"
                            autocomplete="new-username" />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="Mot de passe">
                        <b-form-input type="password" v-model="adminUser.password" placeholder="Mot de passe"
                            @keyup.enter="saveAdminUser" ref="adminUserPasswordInput" id="password" name="password"
                            autocomplete="new-password" />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="saveAdminUser" variant="relief-success">
                        Sauvegarder
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>

        <b-modal hide-footer size="xl" ref="param-modal" title="Paramètrage">
            <b-row>

                <b-col cols="12">
                    <b-form-group label="Description">
                        <b-form-input v-model="company.description" placeholder="Description"
                            ref="companyDescriptionInput" />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="Middleware Domaine">
                        <b-form-input v-model="company.middlewareDomaine" placeholder="Middleware Domaine"
                            @keyup.enter="saveParams" />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="saveParams" variant="relief-success">
                        Sauvegarder
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des sociétés" @refresh="loadData" :add="true"
                    @plusClicked="showModal" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import DropDown from '@/views/components/ag-grid/DropDownCellRenderer.vue';

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            adminUser: {
                isNew: true,
                companyId: 0,
                username: "",
                password: ""
            },
            company: {
                id: null,
                description: "",
                middlewareDomaine: "",
            },
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "ID",
                        field: "id",
                        // width: 60
                    },
                    {
                        headerName: "Description",
                        field: "description",
                        // width: 80,
                    },
                    {
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'dropDown',
                        cellRendererParams: { componentName: "CompanyList", onButtonClick: this.onButtonClick },
                        sortable: false,
                        resizable: false,
                        filter: false,
                        width: 50
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        validateForm() {
            return this.recText.trim() != '';
        },
    },
    created() {
        this.$options.components.dropDown = DropDown;
        this.loadData();
    },
    methods: {
        onButtonClick(rowData, action) {
            switch (action) {
                case 'Config':
                    this.showParamsModal(rowData);
                    break;
                case 'User':
                    this.showAdminUserModal(rowData);
                    break;
                case 'Customer':
                    this.$router.push({ name: 'supervisor-customers', params: { slug: rowData.id } });
                    break;
                case 'Supplier':
                    this.$router.push({ name: 'supervisor-suppliers', params: { slug: rowData.id } });
                    break;
                case 'Item':
                    this.$router.push({ name: 'supervisor-company-items', params: { slug: rowData.id } });
                    break;
            }
        },
        async showAdminUserModal(data) {
            this.showLoading = true;
            this.adminUser = {
                isNew: true,
                companyId: data.id,
                username: "",
                password: ""
            }
            let response = await this.$http.get("account/getAdminAccount/" + data.id).catch(() => {
                this.showLoading = false
            });
            if (response.data != "") {
                this.adminUser.username = response.data.username;
                this.adminUser.isNew = false;
            } else {
                this.adminUser.isNew = true;
            }
            this.$refs['adminUser-modal'].show();
            setTimeout(() => {
                this.adminUser.isNew ? this.$refs["adminUserInput"].focus() : this.$refs["adminUserPasswordInput"].focus();
            }, 300);
            this.showLoading = false;
        },
        async saveAdminUser() {
            this.$refs['adminUser-modal'].hide();
            this.showLoading = !this.showLoading;
            if (this.adminUser.isNew) {
                let response = await this.$http.post("account/addAdminAccount", this.adminUser).catch(() => {
                    this.showLoading = false
                });
                if (response.status == 200) {
                    this.$swal({
                        title: 'compte créé avec succès .',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        timer: 4000,
                        buttonsStyling: false,
                    });
                }
                this.loadData();
            } else {
                let response = await this.$http.post("account/modifyAdminAccount", this.adminUser).catch(() => {
                    this.showLoading = false
                });
                if (response.status == 200) {
                    this.$swal({
                        title: 'Mot de passe modifié avec succès .',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        timer: 4000,
                        buttonsStyling: false,
                    });
                }
                this.loadData();
            }
        },
        showParamsModal(row) {
            this.company = row;
            this.$refs['param-modal'].show();
            setTimeout(() => {
                this.$refs["companyDescriptionInput"].focus();
            }, 300);
        },
        async saveParams() {
            this.$refs['param-modal'].hide();
            this.showLoading = !this.showLoading;
            let response = await this.$http.post("company", this.company).catch(() => {
                this.showLoading = false
            });
            if (response.status == 200) {
                this.$swal({
                    title: 'entreprise créé avec succès .',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    timer: 4000,
                    buttonsStyling: false,
                });
            }
            this.loadData();
        },
        showModal() {
            this.company = {
                id: null,
                description: "",
                middlewareDomaine: "",
            };
            this.$refs['param-modal'].show();
            setTimeout(() => {
                this.$refs["companyDescriptionInput"].focus();
            }, 300);
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("company").catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data.reverse();
            this.showLoading = false;
        }
    }
}
</script>

<style lang="scss"></style>
